<template>
  <div class="wallet-login-wrap">
    <div class="minting-bi-wrap">
      <img class="minting-bi" :src="bi_img">
    </div>
    <div class="wallet-login">
      <br>
      <h3>
        {{language.minting_connect_wallet_title_1}}
        {{minting_info.event.eventId}}
        {{language.minting_connect_wallet_title_2}}
      </h3>
      <br>
      <h3>{{language.minting_connect_wallet_info_title}}</h3>
      <ul class="wallet-login-list">
        <li>{{language[`minting_connect_wallet_mint_event_${minting_info.event.eventId}_info_01`]}}</li>
        <li>{{language[`minting_connect_wallet_mint_event_${minting_info.event.eventId}_info_02`]}}</li>
        <li>{{language[`minting_connect_wallet_mint_event_${minting_info.event.eventId}_info_03`]}}</li>
        <li>{{language[`minting_connect_wallet_mint_event_${minting_info.event.eventId}_info_05`]}}</li>
        <li>{{language[`minting_connect_wallet_mint_event_${minting_info.event.eventId}_info_04`]}}</li>
      </ul>
      <h3>{{language.minting_connect_wallet_check_title}}</h3>
      <ul class="wallet-login-list">
        <li>{{language.minting_connect_wallet_check_01}}</li>
        <li>{{language.minting_connect_wallet_check_02}}</li>
        <li>{{language.minting_connect_wallet_check_03}}</li>
        <li>{{language.minting_connect_wallet_check_04}}</li>
        <li>{{language.minting_connect_wallet_check_05}}</li>
      </ul>
    </div>

    <template v-if="config.hasWallet">
      <Allowance/>
    </template>
    <template v-else>
      <Auth/>
    </template>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Auth from "@/pages/auth/auth";
  import Allowance from "@/pages/auth/Allowance";

  export default {
    props: [
      'bi_img',
      'config',
    ],
    components: {
      Auth,
      Allowance,
    },
    mixins: [],
    computed: {
      ...mapGetters([
        'language',
        'minting_info',
      ]),
    },
  }
</script>

<style lang="scss">
  .wallet-login-wrap {

    .wallet-login {
      max-width: 700px;
      margin: 0 auto;
      font-weight: 500;
      padding: 0 40px;

      h3 {
        font-size: 20px;
        line-height: 1.5em;
        font-weight: 700;
        color: #77eaff;
      }

      .wallet-login-list {
        margin: 0;
        padding: 0;

        li {
          padding-bottom: 10px;
        }
      }
    }
  }
</style>