<template>
  <div class="mint-whitelist-wrap">
    <div class="minting-bi-wrap">
      <img class="minting-bi" :src="bi_img">
    </div>
    <div class="mint-whitelist">
      <br>
      <strong>{{language.minting_isWhitelist}}</strong>
      <p>
        {{language.minting_isWhitelist_sub_01}}
      </p>
    </div>
    <br><br><br>
    <div class="button-wrap">
      <md-button class="ac-wemix-login-btn"
                 @click="onLogout">
        <img :src="icon_wemix" alt="wemix"/>
        {{language.disconnect_wallet_btn}}
      </md-button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: [
      'config',
      'bi_img',
    ],
    components: {},
    mixins: [],
    computed: {
      ...mapGetters([
        'language',
      ]),
    },
    methods: {
      onLogout(event) {
        event.preventDefault();
        this.$emit('onLogout');
      },
    },
    data() {
      return {
        icon_wemix: require("@/assets/img/logos/WEMIX_Symbol.png"),
      }
    }
  }
</script>

<style lang="scss">
  .mint-whitelist-wrap {
    .mint-whitelist {
      max-width: 1000px;
      margin: 100px auto 0;
      text-align: center;

      strong {
        display: inline-block;
        font-weight: 500;
        padding: 0 40px 30px;
        text-align: center;
        font-size: 38px;
        line-height: 50px;
        text-shadow: 0 1px 10px #cdabff;
      }
    }

    .button-wrap {
      padding: 80px 0 130px;
      text-align: center;

      .ac-wemix-login-btn {
        background-color: #fff !important;
        display: inline-block;
        outline: none;
        text-align: center;
        text-decoration: none;
        color: black !important;
        font-size: 22px;
        font-weight: 700;
        -webkit-border-radius: 5rem;
        -moz-border-radius: 5rem;
        border-radius: 5rem;
        -webkit-box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
        box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);

        .md-ripple {
          padding: 15px 30px !important;
        }

        img {
          width: 34px;
          padding-right: 10px;
        }
      }
    }
  }
</style>