<template>
  <div class="ac-wemix-allowance-wrap">
    <md-button class="ac-wemix-allowance-btn"
               @click="onSubmit">
      <img :src="icon_wemix" alt="wemix"/>
      {{language.minting_wemixcreditcheck_btn}}
    </md-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "Allowance",
  components: {},
  computed: {
    ...mapGetters([
      'language',
    ]),
  },
  methods: {
    ...mapActions([
      'SUBMIT_APPROVE_DATA',
    ]),

    onSubmit() {
      this.SUBMIT_APPROVE_DATA();
    },
  },
  data() {
    return {
      icon_wemix: require("@/assets/img/logos/WEMIX_Symbol.png"),
    }
  }
};
</script>

<style lang="scss">
  $ac-section-bg: #ff6371 !default;

  .ac-wemix-allowance-wrap {
    padding: 80px 0 130px;
    text-align: center;

    .ac-wemix-allowance-btn {
      background-color: #fff !important;
      display: inline-block;
      outline: none;
      text-align: center;
      text-decoration: none;
      color: black !important;
      font-size: 22px;
      font-weight: 500;
      -webkit-border-radius: 5rem;
      -moz-border-radius: 5rem;
      border-radius: 5rem;
      -webkit-box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
      box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);

      .md-ripple {
        padding: 15px 30px !important;
      }

      img {
        width: 30px;
        padding-right: 10px;
      }
    }

  }
</style>