<template>
  <div class="wrapper">
    <br><br><br><br>
    <div class="section">
      <div v-if="!{}.hasOwnProperty.call(minting_info, 'event')">
        <div style="text-align: center">
          <img :src="nft_loading" style="max-width: 500px; padding: 0 40px">
        </div>
      </div>
      <div v-else>
        <div v-if="config.isSoldOut">
          <SoldOut :config="config"
                   :bi_img="bi_img"></SoldOut>
        </div>
        <div v-else>
          <br>
          <template v-if="config.isPossibleMintPeriod">
            <template v-if="config.hasWallet" style="color: white; font-weight: 600;">
              <template v-if="!{}.hasOwnProperty.call(minting_user, 'event')">
                <div style="text-align: center">
                  <img :src="nft_loading" style="max-width: 500px; padding: 0 40px">
                </div>
              </template>
              <template v-else>
                <template v-if="config.isWhitelist">

                  <template v-if="config.allowance">
                    <div class="ac-card-container container">
                      <div class="md-layout">
                        <div class="md-layout-item md-size-50 md-small-size-100">
                          <img :src="nft_card_webp"/>
                          <!--<video autoplay loop="" muted="" poster="" playsinline>-->
                            <!--<source :src="nft_card_video" type="video/mp4">-->
                            <!--<source :src="nft_card_webp" type="video/webp">-->
                            <!--<source :src="nft_card_webm" type="video/webm">-->
                          <!--</video>-->
                        </div>
                        <div class="md-layout-item md-size-50 md-small-size-100 md-white">
                          <md-card class="minting-card md-card-contact">
                            <md-card-header class="ac-nft-minting-header text-center">
                              <img class="img"
                                   :src="deco.character">
                            </md-card-header>
                            <md-card-content>
                              <div class="ac-wallet-addr-wrap">
                                <span class="ac-wallet-addr">{{user.walletAddr}}</span>
                                <md-button class="ac-disconnect-button md-default md-sm md-round"
                                           @click="onLogout">
                                  Disconnect Wallet
                                </md-button>
                                <!--<button class="ac-disconnect-button"-->
                                <!--@click="onLogout">-->
                                <!--Disconnect Wallet-->
                                <!--</button>-->
                              </div>

                              <!--<h6 class="card-category text-info">-->
                              <!--<div class="mint-period">-->
                              <!--<template v-if="remaining_time_from === 0 && remaining_time_to === 0">-->
                              <!--대기중 ...-->
                              <!--</template>-->
                              <!--<template v-else-if="remaining_time_from > 0">-->
                              <!--{{minting_info.event.round}}  라운드 시작 까지 남은 시간 <br> {{remaining_count}}-->
                              <!--</template>-->
                              <!--<template v-else-if="remaining_time_to > 0">-->
                              <!--{{minting_info.event.round}}  라운드진행중 <br> {{remaining_count}}남음-->
                              <!--</template>-->
                              <!--<template v-else>-->
                              <!--{{minting_info.event.round}}  라운드완료 <br>-->
                              <!--<router-link  v-if="config.hasNextEvent"-->
                              <!--to="/NFT/schedule">-->
                              <!--다음차 공지-->
                              <!--</router-link>-->
                              <!--</template>-->
                              <!--</div>-->
                              <!--</h6>-->
                              <hr>
                              <div class="md-layout users-wemix">
                                <div class="md-layout-item ac-mint-category">{{language.minting_card_info_02}}</div>
                                <div class="md-layout-item md-auto">
                                  <img style="width: 22px; vertical-align: text-bottom; padding-right: 3px;" :src="icon_wemix" alt="wemix"/>
                                  <strong>{{wemix.utils().fromWei(minting_user.wemix)}}</strong>
                                </div>
                              </div>
                              <hr>
                              <div class="md-layout nft-quantity">
                                <div class="md-layout-item ac-mint-category">{{language.minting_card_info_01}}</div>
                                <div class="md-layout-item md-auto">
                                  <strong>{{minting_info.event.remainStock}} / {{minting_info.event.limitTotal}}</strong>
                                </div>
                              </div>
                              <hr>
                              <div class="md-layout user-limit-count">
                                <div class="md-layout-item ac-mint-category">Per Transaction</div>
                                <div class="ac-per-value md-layout-item md-auto">
                                  Max &nbsp;<strong>1</strong>
                                </div>
                              </div>
                              <hr>
                              <div class="md-layout user-limit-count">
                                <div class="md-layout-item ac-mint-category">Per Wallet</div>
                                <div class="ac-per-value md-layout-item md-auto">
                                  Max &nbsp;<strong>{{minting_info.event.limitUser}}</strong>
                                </div>
                              </div>
                              <hr>
                              <div class="md-layout nft-price">
                                <div class="md-layout-item ac-mint-category">{{language.minting_card_info_03}}</div>
                                <div class="md-layout-item md-auto">
                                  <img style="width: 22px; vertical-align: text-bottom; padding-right: 3px;"  :src="icon_wemix" alt="wemix"/>
                                  <strong>{{wemix.utils().fromWei(minting_info.event.amount)}}</strong>
                                </div>
                              </div>
                              <hr>
                              <div class="ac-mint-fee">
                                {{language.minting_card_info_04}} : 0.005 Wemix Credit
                              </div>

                              <md-button class="ac-nft-minting-btn md-round" @click="onMint" :disabled="!config.isSufficientAmount || !config.isLimitCount || remaining_time_from >= 0 || remaining_time_to === 0 || !minting.isPossibleMintStatus">
                                MINTING
                              </md-button>

                            </md-card-content>
                            <md-card-actions class="ac-nft-minting-error ">
                              <ul>
                                <li v-if="!config.isSufficientAmount">
                                  {{language.minting_card_error_isSufficientAmount}}
                                </li>
                                <li v-if="!config.isLimitCount">
                                  {{language.minting_card_error_isLimitCount}}
                                </li>
                                <li v-if="remaining_time_from > 0">
                                  {{language.minting_card_error_remaining_time_from}}
                                </li>
                                <li v-if="remaining_time_from < 0 && remaining_time_to === 0">
                                  {{language.minting_card_error_remaining_time}}
                                </li>
                                <li v-if="!minting.isPossibleMintStatus">
                                  {{language.minting_card_error_isPossibleMintStatus_01}}<br>
                                  {{language.minting_card_error_isPossibleMintStatus_02}}<br>
                                  {{language.minting_card_error_isPossibleMintStatus_03}}
                                </li>
                              </ul>

                              <ul>
                                <li v-if="minting.isAnotherSignature">
                                  계정에 연결된 지갑 주소와 서명에 사용된 지갑의 주소가 다릅니다. <br> 확인 후 다시 시도해주시기 바랍니다.
                                </li>
                                <li v-if="minting.isMintedFail">
                                  민팅에 실패하였습니다. 다시 시도해주시기 바랍니다.
                                </li>
                              </ul>
                            </md-card-actions>
                          </md-card>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <WalletLogin :config="config"
                                 :bi_img="bi_img"/>
                  </template>
                </template>
                <template v-else>
                  <MintWhitelist :config="config"
                                 :bi_img="bi_img"
                                 @onLogout="onLogout"></MintWhitelist>
                </template>
              </template>

            </template>
            <template v-else>
              <br>
              <WalletLogin :config="config"
                           :bi_img="bi_img"/>
            </template>
          </template>
          <template v-else>
            <br>
            <MintPeriod :config="config"
                        :bi_img="bi_img"></MintPeriod>
          </template>
        </div>
      </div>
    </div>

    <!--<template>-->
      <!--<ul>-->
        <!--<li v-for="(_, key) in config" :key="key">-->
          <!--<el-checkbox v-model="config[key]">{{key}}</el-checkbox>-->
        <!--</li>-->
      <!--</ul>-->

      <!--<ul>-->
        <!--<li v-for="(_, key) in minting" :key="key">-->
          <!--<el-checkbox v-model="minting[key]">{{key}}</el-checkbox>-->
        <!--</li>-->
      <!--</ul>-->
    <!--</template>-->

    <div class="modal modal-bg"
         :class="{ show: isShowErrorModal }" >
      <modal v-if="isShowErrorModal && minted_error['msg']"
             type="modal-error modal-small"
             @close="isShowErrorModal = false">
        <template slot="header">
          <md-button class="md-simple md-close md-just-icon md-round modal-default-button"
                     @click="isShowErrorModal = false">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <p class="modal-error-body" style="color: black">{{language[minted_error.msg]}}</p>
        </template>

        <template slot="footer">
          <div class="modal-error-footer">
            <md-button class="md-round"
                       @click="isShowErrorModal = false">
              {{language.minting_card_error_confirm}}
            </md-button>
          </div>
        </template>
      </modal>
    </div>

    <div class="modal modal-bg"
         :class="{ show: isShowDetailNFTModal }">
      <modal v-if="isShowDetailNFTModal && selectNFT"
             type="modal-minting"
             @close="hideDetailNFTModal">
        <template slot="body">
          <div v-if="$route.name === 'Minting'" class="nft-card-status">
            {{language.minting_card_success}}
            <md-button class="md-simple md-close md-just-icon md-round modal-default-button"
                       @click="hideDetailNFTModal">
              <md-icon>clear</md-icon>
            </md-button>
          </div>

          <NFTViewer :selectNFT="selectNFT"
                     @click="hideDetailNFTModal"/>
        </template>
      </modal>
    </div>

  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import { Modal } from "@/components";
  import Mixins from "@/plugins/basicMixins";
  import timerCount from "@/plugins/timerCount";
  import SoldOut from "@/pages/nft/sub/SoldOut";
  import WalletLogin from "@/pages/nft/sub/WalletLogin";
  import MintPeriod from "@/pages/nft/sub/MintPeriod";
  import MintWhitelist from "@/pages/nft/sub/MintWhitelist";

  import NFTViewer from "@/pages/nft/sub/NFTViewer";

  export default {
    bodyClass: "ac-nft-minting",
    components: {
      Modal,

      SoldOut,
      WalletLogin,
      MintPeriod,
      MintWhitelist,

      NFTViewer,
    },
    mixins: [Mixins.HeaderImage, timerCount],
    computed: {
      ...mapGetters([
        "base",
        'language',
        "isWemixLoggedIn",

        "user",
        "minting_info",
        "minting_user",
        "minted_NFT",
        "minted_error",
        "is_possible_minting_status",
        "wemix",
      ]),
    },
    watch: {
      $route() {
        this.GET_MINTING_INFO_DATA();
      },
      isWemixLoggedIn() {
        this.GET_MINTING_INFO_DATA();
      },
      minting_info(value) {
        if (!{}.hasOwnProperty.call(value, 'event') || value.event === null) {
          // empty
        } else {
          this.waitingTimeFrom = value.event.from;
          this.waitingTimeTo = value.event.to;
          this.config.hasNextEvent = {}.hasOwnProperty.call(value.schedule, value.event.eventId);
          this.config.isSoldOut = value.event.remainStock <= 0;
          this.config.hasWallet = this.isWemixLoggedIn;
          this.config.isPossibleMintPeriod = value.event.from === 0 && value.event.to > 0;
          this.config.isPrivate = value.event.private === 1;

          if (this.isWemixLoggedIn) {
            this.stop();
            this.start(this.count);

            this.GET_MINTING_USER_DATA(this.user.walletAddr);
          }
        }
      },

      minting_user(value) {
        const amountTotal = Number(this.wemix.utils().fromWei(value.event.amountTotal));
        const userWemix = Number(this.wemix.utils().fromWei(value.wemix));
        let isWhitelist = true;

        if (this.config.isPrivate) {
          isWhitelist = value.whitelist.includes(value.event.round);
        }
        this.config.isWhitelist = isWhitelist;
        this.config.isLimitCount = value.event.remainStock > 0;
        this.config.isSufficientAmount = amountTotal <= userWemix;
        this.config.allowance = value.allowance;
      },

      minted_error(value) {
        if (value['msg']) {
          this.isShowErrorModal = true;
        } else {
          this.isShowErrorModal = false;
        }
      },

      is_possible_minting_status(value) {
        this.minting.isPossibleMintStatus = value;
      },

      minted_NFT(value) {
        this.showDetailNFTModal(value);
      }
    },
    mounted() {

      this.GET_MINTING_INFO_DATA();
      // const aaa = {
      //   "tokenId": 1,
      //   "name": "ANIPANG #1",
      //   "image": "https://s3.ap-northeast-1.amazonaws.com/dev.anipangclub/nft/image/item_1.png",
      //   "score": 2713,
      //   "ranking": 582,
      //   "attributes": [
      //     {
      //       "trait_type": "Background",
      //       "value": "Sunset",
      //       "percent": "3.23"
      //     },
      //     {
      //       "trait_type": "Character",
      //       "value": "Ani",
      //       "percent": "16.67"
      //     },
      //     {
      //       "trait_type": "Clothes",
      //       "value": "Windbreaker",
      //       "percent": "4.30"
      //     },
      //     {
      //       "trait_type": "Eyes",
      //       "value": "Pixel sunglasses",
      //       "percent": "5.20"
      //     },
      //     {
      //       "trait_type": "Face",
      //       "value": "Tear drop",
      //       "percent": "4.67"
      //     },
      //     {
      //       "trait_type": "Hand",
      //       "value": "None",
      //       "percent": "77.30"
      //     },
      //     {
      //       "trait_type": "Hat",
      //       "value": "Red hat",
      //       "percent": "3.77"
      //     },
      //     {
      //       "trait_type": "Look",
      //       "value": "Smile",
      //       "percent": "33.33"
      //     },
      //     {
      //       "trait_type": "Objects",
      //       "value": "None",
      //       "percent": "84.37"
      //     }
      //   ],
      //   "price": "10000000000000000000",
      //   "availability": "Available",
      //   "contract": "APNFT",
      //   "contractAddress": "0xfd606748aaf209ef570d79c8a09b5e9dac5b905e",
      //   "owner": null,
      //   "scopeLink": "https://dev-scope.wemix.co/4003/nft/0xfd606748aaf209ef570d79c8a09b5e9dac5b905e/1",
      //   "marketLink": ""
      // }
      // this.showDetailNFTModal(aaa);
    },

    methods: {
      ...mapActions([
        'GET_MINTING_INFO_DATA',
        'GET_MINTING_USER_DATA',
        'SUBMIT_MINTING_DATA',
        'SUBMIT_LOG_OUT',
        'SUBMIT_WALLET_LOGIN_DATA',
        // 'SUBMIT_APPROVE_DATA',
        'SUBMIT_LOG_OUT',
      ]),
      ...mapMutations([
        'SET_WEMIX_AGREE',
      ]),

      onLogout() {
        this.SUBMIT_LOG_OUT();
      },

      // onQRAllowance() {
      //   this.isShowAllowanceModal = false;
      //   this.SUBMIT_APPROVE_DATA();
      // },

      onMint() {
        this.SUBMIT_MINTING_DATA()
      },

      count(title, tick) {
        const remaining_time_from = this.waitingTimeFrom - tick;
        const remaining_time_to = this.waitingTimeTo - tick;
        let gap = remaining_time_from;

        if (remaining_time_from < 0) {
          gap = remaining_time_to;
        }

        const day = String(Math.floor(gap / (60 * 60 * 24)));
        const days = (day > 0) ? `${day}Day ` : '';
        const hour = String(Math.floor((gap % (60 * 60 * 24)) / (60 * 60))).padStart(2, '0');
        const min = String(Math.floor((gap % (60 * 60)) / 60)).padStart(2, '0');
        const sec = String(Math.floor(gap % 60)).padStart(2, '0');

        this.remaining_time_from = remaining_time_from;
        this.remaining_time_to = remaining_time_to;
        this.remaining_count = `${days}${hour} : ${min} : ${sec}`;

        if (remaining_time_from > 0 || remaining_time_to === 0) {
          this.mintAbleTime = false;
        } else  {
          this.mintAbleTime = true;
        }

        if (tick === this.waitingTimeTo) {
          this.stop();
          this.remaining_count = '';
        }
      },

      showDetailNFTModal(selectNFT) {
        this.selectNFT = selectNFT;
        this.isShowDetailNFTModal = true;
      },
      hideDetailNFTModal() {
        this.isShowDetailNFTModal = false;
      },
    },

    data() {
      return {

        anipangNFT_img: require("@/assets/img/pages/nft/anipang_nft.png"),
        bi_img: require("@/assets/img/logos/bi.png"),
        nft_img: require("@/assets/img/pages/nft/nft.png"),
        nft_card_video: require("@/assets/img/pages/nft/nft_card.mp4"),
        nft_card_webp: require("@/assets/img/pages/nft/nft_card.webp"),
        nft_card_webm: require("@/assets/img/pages/nft/nft_card.webm"),
        nft_loading: require("@/assets/img/pages/nft/loading.gif"),

        deco: {
          character: require("@/assets/img/pages/nft/anipang_nft.png"),
          bg_color: '#552da3',
        },


        icon_wemix: require("@/assets/img/logos/WEMIX_Symbol.png"),

        amount: 30,
        amount2: 60,
        buffer: 40,
        waitingTimeFrom: 60,
        remaining_time_from: 0,
        waitingTimeTo: 60,
        remaining_time_to: 0,
        remaining_count: '',
        mintAbleInit: true,
        mintAbleTime: false,
        mintAbleInitReason: [],
        selectNFT: null,
        isShowDetailNFTModal: false,
        isShowErrorModal: false,
        
        config: {
          hasNextEvent: false,
          isSoldOut: true,
          isPossibleMintPeriod: false,
          hasWallet: false,
          isWhitelist: false,
          allowance: false,
          isSufficientAmount: true,
          isLimitCount: true,
          isPrivate: true,
        },

        minting: {
          isAnotherSignature: false,
          isMintedFail: false,
          isPossibleMintStatus: true,
        },
      };
    }
  };
</script>

<style lang="scss">


  $ac-section-bg: #ff6371 !default;


  .ac-nft-minting {
    color: white;

    .wrapper {
      background-image: url("../../assets/img/pages/nft/03_nft_bg3.png");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: cover;

      .minting-bi-wrap {
        text-align: center;

        .minting-bi {
          max-width: 500px;
          width: 100%;
          padding: 0 40px;
        }
      }

      .ac-card-container {
        hr {
          margin: 0.5rem;
        }
      }
      .minting-card {
        font-size: 17px;
        color:  #2f2b5a;

        .md-card-header {
          border-radius: 25px;

          &.ac-nft-minting-header {
            background: #6825cc;
            background: -webkit-linear-gradient(60deg, #6825cc , #552da3); /* For Safari 5.1 to 6.0 */
            background: -o-linear-gradient(60deg, #6825cc , #552da3); /* For Opera 11.1 to 12.0 */
            background: -moz-linear-gradient(60deg, #6825cc , #552da3); /* For Firefox 3.6 to 15 */
            background: linear-gradient(60deg, #6825cc , #552da3); /* Standard syntax */
            padding: 25px 60px 25px;
            box-shadow: 0 8px 10px 1px rgb(104 37 204 / 50%);
            border-radius: 10px;
          }

          img {
            border-radius: 0;
          }
        }

        .ac-per-value {
          display: flex;
          justify-content: flex-end;
          align-items: end;
        }

        .ac-wallet-addr-wrap {
          padding: 10px 0;
          text-align: center;


          .ac-wallet-addr {
            display: inline-block;
            padding-right: 5px;
            font-size: 11px;
            line-height: 11px;
            color: #2f2b5a;
          }
          .ac-disconnect-button {
            margin: 0;
            background-color: #9490BF !important;

            /*border-radius: 30px;*/
            line-height: 11px;

            &.md-button {
              .md-ripple {
                padding: 8px 10px 6px !important;
                font-size: 11px;
                font-weight: 400;
              }
            }
          }
        }

        .nft-quantity {
          font-weight: 700;
          padding: 10px 0;

          .ac-mint-category {
            /*line-height: 15px;*/
          }
          .md-auto {
            text-align: right;
          }

          strong {
            /*font-size: 16px;*/
          }
        }

        .users-wemix {
          font-weight: 700;
          padding: 10px 0;

          .ac-mint-category {
            /*line-height: 15px;*/
          }
          .md-auto {
            text-align: right;
          }
          strong {
            /*font-size: 16px;*/
          }
        }

        .user-limit-count {
          font-weight: 700;
          padding: 10px 0;

          .ac-mint-category {
            /*line-height: 15px;*/
          }
          .md-auto {
            text-align: right;
          }

          strong {
            /*font-size: 16px;*/
          }
        }

        .nft-price {
          font-weight: 700;
          padding: 10px 0;

          .ac-mint-category {
            /*line-height: 15px;*/
          }
          .md-auto {
            text-align: right;
          }

          strong {
            /*font-size: 16px;*/
          }
        }

        .ac-mint-fee {
          text-align: center;
          color: #7c7c7c;
          font-size: 13px;
          margin-bottom: 1rem;
        }

        .ac-nft-minting-btn {
          border-radius: 5px;
          width: 100%;
          margin: 0 auto;
          font-size: 20px;
          font-weight: 800;
          background-color: #2f2b5a !important;
          color: #fff;
          -webkit-box-shadow: 0 2px 2px 0 rgb(85 172 238 / 14%), 0 3px 1px -2px rgb(85 172 238 / 20%), 0 1px 5px 0 rgb(85 172 238 / 12%);
          box-shadow: 0 2px 2px 0 rgb(85 172 238 / 14%), 0 3px 1px -2px rgb(85 172 238 / 20%), 0 1px 5px 0 rgb(85 172 238 / 12%);
        }

        .ac-nft-minting-error {
          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              padding-bottom: 5px;
            }
          }
        }
      }
    }

    .modal-minting {
      max-width: 1140px;
      border-radius: 10px;
      overflow: hidden;
      background-color: transparent;

      .modal-header {
        display: none;
      }
      .modal-body {
        text-align: left;
        padding: 0;
        background: #2f2b5a;

        .nft-card-status {
          color: #9d5bff;
          font-size: 28px;
          line-height: 47px;
          padding: 15px 15px 0 20px;
          font-weight: 800;
        }
      }
    }

    .modal-error {
      .modal-error-body {
        font-weight: 700;
        text-align: left;
      }
      .modal-error-footer {
        text-align: center;
        display: block;
        width: 100%;
        overflow: hidden;

        .md-button {
          width: 40%;
          margin: 0 5%;
          font-size: 16px;
          font-weight: 800;

          .md-ripple {
            padding: 10px 10px;
            background-color: #2f2b5a;
          }
        }
      }
    }

  }

  .nft-card {
    background-color: #2f2b5a;
    font-family: "NanumSquareEB";
    font-stretch: normal;
    font-style: normal;

    border-radius: 10px;

    .nft-card-img {
      padding: 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }

</style>
