<template>
  <div class="mint-period-wrap">
    <div class="minting-bi-wrap">
      <img class="minting-bi" :src="bi_img">
    </div>
    <div class="mint-period">
      {{language.minting_isPossibleMintPeriod}}
    </div>
    <br><br><br>
    <div class="button-wrap">
      <router-link class="schedule-link-btn md-info md-round" to="/NFT/schedule">
        {{language.minting_isPossibleMintPeriod_btn}}
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: [
      'config',
      'bi_img',
    ],
    components: {},
    mixins: [],
    computed: {
      ...mapGetters([
        'language',
      ]),
    },
    methods: {}
  }
</script>

<style lang="scss">
  .mint-period-wrap {
    .mint-period {
      max-width: 700px;
      margin: 100px auto 0;
      font-weight: 500;
      padding: 0 40px;
      text-align: center;
      font-size: 38px;
      line-height: 50px;
      text-shadow: 0 1px 10px #cdabff;
    }

    .button-wrap {
      padding: 80px 0 130px;
      text-align: center;
      background-image: url("../../../assets/img/pages/nft/03_nft_bg2.png");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: auto;

      .schedule-link-btn {
        background-color: #6825cc !important;
        display: inline-block;
        outline: none;
        text-align: center;
        text-decoration: none;
        color: #fff !important;
        font-size: 27px;
        font-weight: 600;
        padding: 25px 30px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
        -webkit-border-radius: 5rem;
        -moz-border-radius: 5rem;
        border-radius: 5rem;
        -webkit-box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
        box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);

        &:hover,
        &:focus {
          color: #77eaff !important;
        }
      }
    }
  }
</style>