<template>
  <div class="sold-out-wrap">
    <div class="minting-bi-wrap">
      <img class="minting-bi" :src="bi_img">
    </div>
    <div class="sold-out">
      <br>
      <div v-if="config.hasNextEvent">
        <h3>
          {{language.minting_hasNextEvent_true_01}}
          {{minting_info.event.eventId}}
          {{language.minting_hasNextEvent_true_02}}
        </h3>
        <br>
        {{language[`minting_hasNextEvent_${minting_info.event.eventId}_true_05`]}}<br>
        {{language[`minting_hasNextEvent_${minting_info.event.eventId}_true_06`]}}<br>
        {{language[`minting_hasNextEvent_${minting_info.event.eventId}_true_07`]}}
      </div>
      <div v-else>
        <h3>
          {{language.minting_hasNextEvent_false_01}}
        </h3>
        <br>
        {{language.minting_hasNextEvent_false_02}}<br>
      </div>
      <br>
      {{language.minting_hasNextEvent_01}}<br>
      {{language.minting_hasNextEvent_02}}<br>
      {{language.minting_hasNextEvent_03_01}}
      <a class="ac-nft-market-link" href="https://market.wemixnetwork.com" target="_blank">WEMIX NFT Marketplace</a>
      {{language.minting_hasNextEvent_03_02}}<br>
      {{language.minting_hasNextEvent_04}}<br>
    </div>
    <br>
    <div v-if="config.hasNextEvent" class="button-wrap">
      <router-link class="schedule-link-btn md-info md-round" to="/NFT/schedule">
        {{language.minting_isPossibleMintPeriod_btn}}
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: ['config', 'bi_img'],
    components: {},
    mixins: [],
    computed: {
      ...mapGetters([
        'language',
        'minting_info',
      ]),
    },
  }
</script>

<style lang="scss">
  .sold-out-wrap {
    padding: 80px 0 130px;

    .sold-out {
      font-size: 15px;
      max-width: 700px;
      margin: 0 auto;
      font-weight: 500;
      padding: 0 40px;

      h3 {
        font-size: 20px;
        line-height: 1.5em;
        font-weight: 700;
        color: #77eaff;
      }

      .ac-nft-market-link {
        color: deepskyblue;
        font-weight: 700;
      }
    }
    .button-wrap {
      padding: 80px 0 130px;
      text-align: center;
      background-image: url("../../../assets/img/pages/nft/03_nft_bg2.png");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: auto;

      .schedule-link-btn {
        background-color: #6825cc !important;
        display: inline-block;
        outline: none;
        text-align: center;
        text-decoration: none;
        color: #fff !important;
        font-size: 27px;
        font-weight: 600;
        padding: 25px 30px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
        -webkit-border-radius: 5rem;
        -moz-border-radius: 5rem;
        border-radius: 5rem;
        -webkit-box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
        box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);

        &:hover,
        &:focus {
          color: #77eaff !important;
        }
      }
    }
  }
</style>